@tailwind base;
@tailwind components;
@tailwind utilities;

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.25rem;
}